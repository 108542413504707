<script lang="ts" setup>
import { useStyle3d } from './style3d.ts';
import type { Style3dOptions } from './typing.ts';
import { onMounted, watch } from 'vue';

const props = defineProps<{
  options: Style3dOptions
}>();

const emit = defineEmits<{
  loaded: []
  ready: [ready: boolean]
  'material-selected': [id: string]
}>();

const style3dInstance = useStyle3d();

watch(() => style3dInstance.isReady, (v) => {
  emit('ready', v);
});

onMounted(async () => {
  await style3dInstance.initStyle3d({
    ...props.options,
    events: {
      onMaterialSelected(selectedMaterial) {
        emit('material-selected', selectedMaterial.infoId);
      }
    }
  })
    .then(() => {
      emit('loaded');
    });
});

</script>

<template>
  <div style="width: 100%; height: 100%;" id="style3d-con" @dragover.prevent></div>
</template>
<style scoped>
</style>
